import { Box, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

export default function ButtonsYesNo({
  onSave,
  avaliado,
  pontuacao_real,
  resposta,
  item,
}) {
  const [botaoClicado, setBotaoClicado] = useState(
    avaliado === "S" ? resposta : null
  );
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();
  const { userProfile, setUserProfile } = useAppContext();

  useEffect(() => {
    setBotaoClicado(avaliado === "S" ? resposta : null);
  }, [avaliado, resposta]);

  const handleButtonClick = (resposta) => {
    setBotaoClicado(resposta);
    onSave(resposta, item.fator, item.avaliado, item.pontuacao_real);
  };

  const colorSchemeSim = botaoClicado === "S" ? "green" : "gray";
  const colorSchemeNao = botaoClicado === "N" ? "red" : "gray";

  let isDisabled =
    anoGlobal !== anoGlobalAtivo || trimestreGlobal !== trimestreGlobalAtivo;

  if (userProfile === "Administrador") {
    isDisabled = false; // Desativar a desativação se o usuário for um administrador
  }
  return (
    <Box>
      <Button
        mt="10px"
        colorScheme={colorSchemeSim}
        onClick={() => handleButtonClick("S")}
        isDisabled={isDisabled}
      >
        Sim
      </Button>
      <Button
        ml="10px"
        mt="10px"
        colorScheme={colorSchemeNao}
        onClick={() => handleButtonClick("N")}
        isDisabled={isDisabled}
      >
        Não
      </Button>
    </Box>
  );
}
