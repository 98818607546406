import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  Center,
} from "@chakra-ui/react";

import {
  distCredentials,
  validCredentials,
} from "../helpers/mock/validCredentials-mock";
import { recuperarSenhaPost } from "../api/recuperar-senha-api";
import { getToken } from "../api/get-token-api";
import { useNavigate } from "react-router-dom";

const RecoverPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Envie a solicitação à API de recuperação de senha
      const response = await recuperarSenhaPost(email);

      // Verifique o código de status da resposta
      if (response.mensagem === "e-mail enviado!") {
        setMessage(
          "Um e-mail de recuperação de senha foi enviado para o endereço fornecido."
        );
        setErrorMessage(""); // Limpa a mensagem de erro se houver
      } else {
        // Se a resposta indicar um erro, exiba a mensagem de erro fornecida pela API
        setErrorMessage(
          "Erro ao processar a solicitação de recuperação de senha. Tente novamente mais tarde."
        );
        setMessage(""); // Limpa a mensagem de sucesso se houver
      }
    } catch (error) {
      // Se ocorrer um erro durante a solicitação, trate-o aqui
      console.error("Erro ao processar a solicitação:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      maxW="400px"
      mx="auto"
      mt="100px"
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
    >
      <Heading as="h2" size="xl" textAlign="center" mb="6">
        Recuperar Senha
      </Heading>
      {errorMessage && (
        <Text mb="4" textAlign="center">
          {errorMessage
            .split("Erro ao processar a solicitação de recuperação de senha. ")
            .map((part, index) => (
              <span key={index}>
                {index === 0 ? (
                  <Text as="span" fontWeight="bold" color="red">
                    Erro ao processar a solicitação de recuperação de senha.
                  </Text>
                ) : (
                  <Text as="span" fontWeight="bold" color="red">
                    Tente novamente mais tarde.
                  </Text>
                )}
              </span>
            ))}
        </Text>
      )}

      {message && (
        <Text mb="4" textAlign="center">
          {message
            .split("Um e-mail de recuperação de senha")
            .map((part, index) => (
              <span key={index}>
                {index === 0 ? (
                  <Text as="span" fontWeight="bold" color="green">
                    Um e-mail de recuperação de senha
                  </Text>
                ) : (
                  <span>{part}</span>
                )}
              </span>
            ))}
        </Text>
      )}
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          placeholder="Digite seu e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <Button
        colorScheme="whatsapp"
        color="white"
        isLoading={isSubmitting}
        mt="4"
        w="100%"
        onClick={() => {
          handleSubmit();
          setMessage("");
          setErrorMessage("");
        }}
      >
        Recuperar Senha
      </Button>
      <Center>
        <Text
          mt="20px"
          as="u"
          fontSize="xs"
          _hover={{ color: "black", fontSize: "md", cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Voltar
        </Text>
      </Center>
    </Box>
  );
};

export default RecoverPassword;
