// export const numerosNaoRequeremEvidencia = [
//   "1.1.6",
//   "2.1.4",
//   "2.1.5",
//   "2.1.6",
//   "2.1.7",
//   "4.1.1",
//   "5.1.4",
//   "6.2.1",
//   "6.2.3",
//   "6.2.7",
//   "6.3.1",
//   "6.3.2",
//   "6.4.5",
//   "6.5.1",
//   "6.5.2",
//   "7.1.2",
//   "7.1.3",
//   "7.1.4",
//   "7.2.4",
//   "7.2.6",
//   "7.2.7",
//   "7.3.10",
//   "7.3.4",
//   "7.3.5",
//   "7.3.6",
//   "7.3.7",
//   "7.4.1",
//   "7.4.2",
//   "7.5.5",
//   "7.5.7",
//   "7.6.3",
//   "7.7.1",
//   "7.7.2",
//   "10.1.1",
//   "10.1.2",
//   "10.1.3",
//   "10.1.4",
//   "10.2.1",
//   "10.2.2",
//   "10.3.1",
//   "10.3.2",
//   "10.3.3",
//   "11.1.1",
//   "11.1.2",
//   "11.1.3",
//   "11.1.4",
//   "11.1.5",
//   "11.1.6",
//   "2.1.1",
//   "2.1.2",
//   "2.1.3",
//   "6.4.1",
//   "6.4.2",
//   "8.1.1",
//   "8.1.6",
//   "8.2.1",
//   "8.2.2",
//   "8.2.3",
//   "8.2.4",
//   "9.1.1",
//   "9.1.2",
//   "9.1.3",
//   "9.2.1",
//   "9.2.2",
//   "9.2.3",
//   "9.2.4",
//   "9.2.5",
//   "9.2.6",
//   "9.2.7",
//   "9.3.1",
//   "9.3.2",
//   "9.3.3",
//   "9.4.1",
//   "9.4.2",
//   "9.4.3",
//   "9.4.4",
// ];

export const numerosNaoRequeremEvidencia = [
  "3.1.1",
  "3.1.2",
  "3.1.3",
  "3.1.4",
  "3.1.5",
  "3.1.6",
  "4.1.4",
  "5.3.1",
  "5.4.1",
  "5.4.2",
  "5.4.3",
  "5.5.2",
  "6.1.2",
  "6.1.4",
  "6.2.1",
  "6.2.2",
  "6.2.3",
  "6.3.1",
  "6.3.2",
  "6.3.3",
  "6.6.1",
  "7.1.1",
  "7.2.1",
  "7.2.2",
  "7.2.3",
  "7.2.4",
  "7.2.5",
  "8.1.1",
  "8.1.2",
  "8.1.3",
  "8.2.1",
  "8.3.1",
  "8.3.2",
  "8.3.3",
  "8.3.4",
  "9.1.1",
  "9.1.2",
  "9.1.3",
  "9.1.4",
  "9.2.1",
  "9.2.2",
  "9.3.1",
  "9.3.2",
  "9.3.3",
  "10.1.1",
  "10.1.2",
  "10.1.3",
  "10.1.4",
  "10.1.5",
  "10.1.6",
];
