import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  Spinner,
  Progress,
  InputGroup,
  InputRightElement,
  Center,
  Image,
  HStack,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { validCredentials } from "../helpers/mock/validCredentials-mock";
import { getToken } from "../api/get-token-api";
import { getAllFileNames } from "../api/get-files-name-jc-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { distCredentials } from "../helpers/mock/validCredentials-mock";
import { useMsal } from "@azure/msal-react";
import { FaSignInAlt } from "react-icons/fa";
import { getActiveYear } from "../api/get-active-year";
import PexBG from "../helpers/mock/files/pex-img-teste123.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0); // Adicionado estado para a barra de progresso
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // Estado para controlar a visibilidade da senha
  const { distribuidor, setDistribuidorGlobal } = useAppContext();
  const { globalToken, setGlobalToken } = useAppContext();
  const { user, setUser } = useAppContext();
  const { userProfile, setUserProfile } = useAppContext();
  const { getFileNameAnualApiGlobalState, setGetFileNameAnualApiGlobalState } =
    useAppContext();
  const {
    getFileNameTrimestralApiGlobalState,
    setGetFileNameTrimestralApiGlobalState,
  } = useAppContext();
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const { getItemsPexGlobalState, setGetItemsPexGlobalState } = useAppContext();
  const { getItemsPontuacaoAnualApi, setGetItemsPontuacaoAnualApi } =
    useAppContext();
  const { getItemsPontuacaoTrimestralApi, setGetItemsPontuacaoTrimestralApi } =
    useAppContext();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();

  const { emailUsuario, setEmailUsuario } = useAppContext();
  const { acessoUsuario, setAcessoUsuario } = useAppContext();
  const { nivelAcesso, setNivelAcesso } = useAppContext();

  const { instance } = useMsal();

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(true);

        const storedProfile = userProfile;
        if (storedProfile) {
          setUserProfile(storedProfile);

          // Se o usuário estiver autenticado, redirecione para a página apropriada
          switch (storedProfile) {
            case "Avaliador":
              navigate("/avaliador");
              break;
            case "Administrador":
              navigate("/editar-remover-item");
              break;
            // Adicione outros casos conforme necessário

            default:
              navigate("/evidencias");
          }
        }
      } catch (error) {
        setError("Erro ao verificar autenticação. Tente novamente.");
        console.error("Erro em checkAuth:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const getAllPexApis = async () => {
    try {
      setLoading(true);
      setProgress(0); // Inicia a barra de progresso

      const getTokenApi = await getToken(emailGlobal, passwordGlobal);
      setGlobalToken(getTokenApi.access_token);
      setProgress(20); // Atualiza a barra de progresso

      const getFileNamesAnual = await getAllFileNames("A", distribuidor);
      const getFileNamesTrimestral = await getAllFileNames("T", distribuidor);
      setProgress(40); // Atualiza a barra de progresso

      const getAllItems = await getAllItemsPex(globalToken, 1);
      setProgress(60); // Atualiza a barra de progresso

      const getPexPontuacaoAnual = await getAllItemsPexWithPoints(
        globalToken,
        "Anual",
        distribuidor,
        anoGlobal,
        trimestreGlobal
      );
      const getPexPontuacaoTrimestral = await getAllItemsPexWithPoints(
        globalToken,
        "Trimestral",
        distribuidor,
        anoGlobal,
        trimestreGlobal
      );
      setProgress(80); // Atualiza a barra de progresso

      setGetFileNameAnualApiGlobalState(getFileNamesAnual);
      setGetFileNameTrimestralApiGlobalState(getFileNamesTrimestral);
      setGetItemsPexGlobalState(getAllItems);
      setGetItemsPontuacaoAnualApi(getPexPontuacaoAnual);
      setGetItemsPontuacaoTrimestralApi(getPexPontuacaoTrimestral);

      setProgress(100); // Conclui a barra de progresso
    } catch (error) {
      setError("Erro ao carregar APIs. Tente novamente.");
      console.error("Erro em getAllPexApis:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const tokenzao = await getToken(email, password);

      if (tokenzao.code !== 401) {
        setGlobalToken(tokenzao.access_token);
        const getAnoAtivo = await getActiveYear(tokenzao.access_token);
        setAnoGlobal(getAnoAtivo.ano);
        setTrimestreGlobal(getAnoAtivo.referencia);
        setAnoGlobalAtivo(getAnoAtivo.ano);
        setTrimestreGlobalAtivo(getAnoAtivo.referencia);
        handleLogin();
      } else {
        setError("Credenciais inválidas. Tente novamente.");
      }
    } catch (error) {
      console.error("Ocorreu um erro durante a execução do código:", error);
      setError(
        "Ocorreu um erro durante o processo. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    setEmailGlobal(email.toLowerCase());
    setPasswordGlobal(password);
    const normalizedEmail = email.toLowerCase();

    let user = validCredentials.find(
      (u) => u.email.toLowerCase() === normalizedEmail
    );

    if (!user) {
      user = distCredentials.find(
        (u) => u.email.toLowerCase() === normalizedEmail
      );
    }

    if (user) {
      setError(null);

      setUser(user.email);

      setUserProfile(user.profile);

      setAcessoUsuario(user.access);

      setNivelAcesso(user.nivelAvaliador);

      // Armazenar o perfil de usuário no armazenamento local
      // localStorage.setItem("userProfile", user.profile);
      // localStorage.setItem("userEmail", user.email);
      // Armazenar os acessos do usuário no armazenamento local
      // localStorage.setItem("userAccess", JSON.stringify(user.access));
      // localStorage.setItem("userNivelAcess", user.nivelAvaliador);

      if (user.profile === "Avaliador") {
        navigate("/avaliador");
      } else if (user.profile === "Administrador") {
        setDistribuidorGlobal("QUITE");

        navigate("/editar-remover-item");
      } else {
        // Definir o distribuidor no estado global
        setDistribuidorGlobal(user.empresa);
        navigate("/evidencias");
        // Tratar outros perfis, se necessário
      }
    } else {
      setError("Credenciais inválidas. Tente novamente.");
    }
  };

  return (
    <>
      <Center h="100vh">
        <Box
          bg="white"
          as="form"
          onSubmit={handleSubmit}
          minW="75%"
          minH="70%"
          mx="auto"
          mr="330px"
          p="2"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
        >
          <HStack spacing="24px">
            <Box flex="1">
              <Center flexDirection="column">
                <HStack spacing={5}>
                  <Tooltip
                    label="NEXUS CONSULTORIA"
                    fontSize="md"
                    placement="top"
                  >
                    <Link
                      href="https://www.nexusconsultoriams.com.br/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src="https://www.nexusconsultoriams.com.br/images/logo_nexus_consult.png"
                        alt="João Carlos"
                        borderRadius="full"
                        boxSize="45px"
                        style={{ objectFit: "contain" }}
                        _hover={{ cursor: "pointer" }}
                      />
                    </Link>
                  </Tooltip>
                  {/* <Image
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Raizen.svg/1200px-Raizen.svg.png"
                    alt="Logo Raizen"
                    borderRadius="full"
                    boxSize="45px"
                    style={{ objectFit: "contain " }}
                  />
                  <Image
                    src="https://contabilidadevittoriavanin.com.br/wp-content/uploads/2023/03/como-abrir-franquia-do-posto-shell-e1679299922711.jpg"
                    alt="Logo Shell"
                    borderRadius="full"
                    boxSize="45px"
                    style={{ objectFit: "contain " }}
                  /> */}
                </HStack>
                <Heading as="h2" size="xl" textAlign="center" mb="6">
                  Bem vindo!
                </Heading>
                {error && (
                  <Alert status="error" mb="4">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
                <Stack spacing="4" align="center">
                  <FormControl isRequired>
                    <FormLabel>Email</FormLabel>
                    <Input
                      w="350px"
                      type="text"
                      placeholder="Digite seu e-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Senha</FormLabel>
                    <InputGroup>
                      <Input
                        w="350px"
                        type={showPassword ? "text" : "password"}
                        placeholder="Digite sua senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="whatsapp"
                    color="white"
                    bg="black"
                    disabled={loading}
                    isLoading={loading}
                    w="350px"
                    mb={5}
                  >
                    {loading ? "Carregando..." : "Entrar"}
                  </Button>
                  <Center>
                    <Text
                      onClick={() => navigate("/recuperar-senha")}
                      as="u"
                      fontSize="xs"
                      _hover={{
                        color: "black",
                        fontSize: "md",
                        cursor: "pointer",
                      }}
                    >
                      Primeiro Acesso / Esqueci senha
                    </Text>
                  </Center>
                </Stack>
                {/* <Box
                  w="400px"
                  mx="auto"
                  mt="100px"
                  p="6"
                  borderWidth="1px"
                  borderRadius="lg"
                  // boxShadow="lg"
                >
                  <Center mb="4">
                    <Image
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Raizen.svg/1200px-Raizen.svg.png"
                      alt="Raizen Logo"
                      w="100px"
                      h="auto"
                    />
                  </Center>
                  <Center mb="4">
                    <Button
                      leftIcon={<FaSignInAlt />}
                      bg="#6F0070"
                      color="white"
                      _hover={{ bg: "#4c274c", color: "white" }}
                      onClick={initializeSignIn}
                    >
                      LOGIN RAÍZEN
                    </Button>
                  </Center>
                </Box> */}
              </Center>
            </Box>
            <Box
              // flex="1"
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                src={PexBG}
                alt="Imagem de Fundo Raizen"
                borderRadius="lg"
                boxSize="80%"
                objectFit="cover"
              />
            </Box>
          </HStack>
        </Box>
      </Center>
    </>
  );
};

export default LoginPage;
