import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { getToken } from "../api/get-token-api";
import { getAnexos } from "../api/get-anexo-api";
import { Button, Progress, Text } from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";

function base64toBlob(base64String, contentType) {
  contentType = contentType || "";
  try {
    // Padroniza o comprimento da string
    while (base64String.length % 4) {
      base64String += "=";
    }

    var sliceSize = 1024;
    var byteCharacters = atob(base64String);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    throw error;
  }
}

function DocViewerComponent({ chave, pagina, tamanhoPagina }) {
  const [token, setToken] = useState(null);
  const [dataState, setDataState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isIndeterminate, setIsIndeterminate] = useState(true);
  const [arquivosCarregados, setArquivosCarregados] = useState(0);
  const [cancelado, setCancelado] = useState(false); // Estado para controlar se o carregamento foi cancelado
  const { emailGlobal, passwordGlobal } = useAppContext();
  const [error, setError] = useState(null); // Estado para armazenar mensagem de erro

  const getMimeType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();

    const mimeTypes = {
      bmp: "image/bmp",
      csv: "text/csv",
      odt: "application/vnd.oasis.opendocument.text",
      doc: "application/msword",
      docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      gif: "image/gif",
      htm: "text/htm",
      html: "text/html",
      jpg: "image/jpg",
      jpeg: "image/jpeg",
      pdf: "application/pdf",
      png: "image/png",
      ppt: "application/vnd.ms-powerpoint",
      pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      tiff: "image/tiff",
      txt: "text/plain",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      mp4: "video/mp4",
    };

    return mimeTypes[extension] || "";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);

        let currentPage = 1;
        let hasNextPage = true;
        const pageSize = 10;

        setIsIndeterminate(true);

        while (hasNextPage && !cancelado) {
          // Verifica se o carregamento foi cancelado
          const chaveItems = await getAnexos(
            tokenData.access_token,
            "ZC4",
            chave,
            currentPage,
            pageSize
          );

          setDataState((prevData) => [...prevData, ...chaveItems.files]);
          setArquivosCarregados(
            (prevCount) => prevCount + chaveItems.files.length
          );

          hasNextPage = chaveItems.hasNext;

          currentPage++;
        }

        setIsIndeterminate(false);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
        setError(
          "Ocorreu um erro ao buscar os documentos. Por favor, tente novamente."
        );
        setLoading(false);
      }
    };

    fetchData();
  }, [chave, cancelado]); // Adiciona cancelado como dependência do useEffect

  const handleCancel = () => {
    setCancelado(true); // Define o estado de cancelado como verdadeiro
  };

  const docs = dataState.map((item) => ({
    uri: URL.createObjectURL(
      base64toBlob(item.fileBase64, getMimeType(item.file))
    ),
    fileName: item.file,
  }));

  return (
    <div>
      {error && (
        <Text color="red.500" marginTop="4">
          {error}
        </Text>
      )}
      {loading && !cancelado ? (
        <>
          <p>{`Carregando... (${arquivosCarregados} evidências carregadas)`}</p>
          <Progress
            size="sm"
            isIndeterminate={isIndeterminate}
            colorScheme="yellow"
          />
          <Button onClick={handleCancel} marginTop="4">
            Cancelar
          </Button>
        </>
      ) : (
        <>
          {cancelado && (
            <Text color="red.500" marginTop="4">
              Carregamento cancelado devido a tempo de espera muito longo.
            </Text>
          )}
          {dataState.length === 0 && !error && (
            <Text color="gray.500" marginTop="4">
              Não há evidências disponíveis.
            </Text>
          )}
          {dataState.length > 0 && (
            <DocViewer
              style={{ height: "900px", width: "1350px" }}
              documents={docs}
              initialActiveDocument={docs[0]}
              pluginRenderers={DocViewerRenderers}
              language="pt"
            />
          )}
        </>
      )}
    </div>
  );
}

export default DocViewerComponent;
