// Distribuidores.js

import { InputGroup, InputLeftAddon, Select, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { distribuidores } from "../helpers/mock/distribuidores-mock";
import { useAppContext } from "../context/AppContext";
import { useParams } from "react-router-dom";

export default function Distribuidores() {
  const { distribuidor, setDistribuidorGlobal } = useAppContext();
  const navigate = useNavigate();
  const { ano, trimestre } = useParams();

  // Obtenha uma lista de valores únicos para envelope_fee
  const uniqueEnvelopeFees = Array.from(
    new Set(distribuidores.map((item) => item.envelope_fee))
  );

  const handleChange = (event) => {
    const selectedFilial = event.target.value;
    const selectedDistribuidor =
      event.target.options[event.target.selectedIndex].text;

    // Atualize o estado global usando setDistribuidorGlobal
    setDistribuidorGlobal(selectedDistribuidor);

    if (selectedDistribuidor && selectedFilial) {
      const route = `/avaliador/${encodeURIComponent(ano)}/${encodeURIComponent(
        trimestre
      )}/${encodeURIComponent(selectedDistribuidor)}`;

      navigate(route);
    }
  };

  return (
    <Stack direction="row" marginTop={"10px"}>
      <InputGroup>
        <InputLeftAddon children="Distribuidor" width="175px" height="48px" />
        <Select
          width="450px"
          size="lg"
          marginLeft="0px"
          style={{
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
          onChange={handleChange}
        >
          <option value="">Selecione um distribuidor</option>
          {uniqueEnvelopeFees.map((envelopeFee) => (
            <option key={envelopeFee} value={envelopeFee}>
              {envelopeFee}
            </option>
          ))}
        </Select>
      </InputGroup>
    </Stack>
  );
}
