import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
  Text,
  Stack,
  Box,
  Textarea,
  Spinner, // Importe o componente Spinner do Chakra-UI
} from "@chakra-ui/react";
import { getToken } from "../api/get-token-api";
import { saveAvaliation } from "../api/save-avaliation-api";
import { useAppContext } from "../context/AppContext";

export default function AlertBeforeSaveAval({
  numero,
  onAlertBeforeSave,
  titulo,
  pontuacaoReal,
  pontuacaoMaxima,
  pontos,
  arquivos,
  pontosSelecionados,
  avaliado,
  onSave,
  pontuacaoDoItem,
  novaResposta,
  criterio,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [observacao, setObservacao] = useState("");
  const [caracteresRestantes, setCaracteresRestantes] = useState(250);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  // const [token, setToken] = useState("");
  const handleChange = (e) => {
    const texto = e.target.value;
    setObservacao(texto);
    setCaracteresRestantes(250 - texto.length);
  };

  const handleSave = () => {
    onSave(observacao);
  };
  const handleClick = async () => {
    try {
      setLoading(true); // Apenas uma chamada aqui é suficiente
      const getTokenApi = await getToken(emailGlobal, passwordGlobal);
      resetStates(); // Limpa os estados de sucesso e erro

      const token = getTokenApi.access_token;

      const bodyPostAval = [
        {
          ...novaResposta,
          revisao: "2",
          observacao: observacao,
          periodo:
            novaResposta &&
            novaResposta.periodo &&
            novaResposta.periodo.startsWith("T")
              ? "Trimestral"
              : "Anual",
        },
      ];

      const postAvaliation = await saveAvaliation(token, bodyPostAval);

      if (postAvaliation && postAvaliation.codigo === "100") {
        setSuccess(true); // Define o estado de sucesso
        onSave(novaResposta.numero);
        onClose();
      } else {
        if (postAvaliation && postAvaliation.message) {
          setError(true); // Define o estado de erro
        } else {
          console.error(
            "Erro desconhecido ao salvar a avaliação:",
            postAvaliation
          );
        }
      }
    } catch (error) {
      setError(true); // Em caso de erro, define o estado de erro
      console.error("Erro ao salvar a avaliação:", error);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento, independente de sucesso ou erro
    }
  };

  const handleAlertClose = () => {
    onClose();
    setSuccess(false);
    setError(false);
    setLoading(false);
  };

  const resetStates = () => {
    setLoading(false);
    setSuccess(false);
    setError(false);
  };

  return (
    <>
      <Button
        variant={avaliado === "S" ? "solid" : "outline"}
        ml="25px"
        width="15%"
        colorScheme={avaliado === "S" ? "green" : "black"}
        onClick={onOpen}
        mt="10px"
        mb="20px"
        _hover={{
          bg: ("green.100", "green.500"),
          color: "white",
        }}
        _focus={{
          bg: ("green.100", "green.500"),
        }}
      >
        {avaliado === "S" ? "Reavaliar Item" : "Enviar Avaliação"}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={handleAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {`${numero} - ${titulo}`}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack direction="column" spacing={2}>
                <Text>Pontuação Máxima do item: {pontuacaoMaxima}</Text>
                {criterio === "V" ? (
                  <Text>
                    Pontuação Real atingida:{" "}
                    {pontuacaoDoItem === 0
                      ? 0
                      : pontuacaoDoItem || pontuacaoReal}
                  </Text>
                ) : (
                  <Text>
                    Pontuação Real atingida:{" "}
                    {novaResposta && novaResposta.resposta === "S"
                      ? pontuacaoMaxima
                      : 0}
                  </Text>
                )}

                <Text>Evidências analisadas:</Text>
                <Box
                  borderWidth="1px"
                  borderRadius="5px"
                  borderColor="gray.200"
                >
                  <ul style={{ listStyleType: "none" }}>
                    {arquivos.map((arquivo, index) => (
                      <li
                        style={{ marginLeft: "10px", color: "#718096" }}
                        key={index}
                      >
                        {arquivo}
                      </li>
                    ))}
                  </ul>
                </Box>
                <Box>
                  <Text>Observação:</Text>
                  <Textarea
                    placeholder="Informe de forma objetiva a justificativa da pontuação atribuída."
                    maxLength={250}
                    value={observacao}
                    onChange={handleChange}
                  />
                  <Text
                    mt={2}
                    fontSize="xs"
                    textAlign="right"
                    color={caracteresRestantes < 0 ? "red" : "inherit"}
                  >
                    {caracteresRestantes} caracteres restantes
                  </Text>
                </Box>
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={handleAlertClose}>
                Fechar
              </Button>
              {criterio === "V" ? (
                <Button
                  colorScheme="green"
                  onClick={() => handleClick()}
                  ml={3}
                  isDisabled={
                    (pontuacaoDoItem < pontuacaoMaxima &&
                      observacao.length === 0) ||
                    pontuacaoDoItem === undefined
                  }
                >
                  Salvar
                </Button>
              ) : (
                <Button
                  colorScheme="green"
                  onClick={() => handleClick()}
                  ml={3}
                  isDisabled={
                    novaResposta === undefined ||
                    (novaResposta &&
                      novaResposta.resposta === "N" &&
                      observacao.length === 0)
                  }
                >
                  Salvar
                </Button>
              )}
              <Stack ml="10px">
                {pontuacaoDoItem === undefined ? (
                  <Text fontSize="sm" color="red.500">
                    Não houve alteração no item, favor selecione uma pontuação
                    diferente para reavaliar o item.
                  </Text>
                ) : (
                  ""
                )}
              </Stack>
            </AlertDialogFooter>
            {loading ? (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Spinner />
              </Box>
            ) : (
              <>
                {success && (
                  <Text color="green.500">Avaliação salva com sucesso!</Text>
                )}

                {error && (
                  <Text color="red.500">Erro ao salvar a avaliação.</Text>
                )}
              </>
            )}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
