export async function getAllPontuacao(ano, referencia, revisao) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Basic bmV4dXMuYW5kcmU6amNj");

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/avaliacoes_pex/distribuidores?ano=${ano}&referencia=${referencia}&revisao=${revisao}`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error get pex points all:", error);
  }
}
