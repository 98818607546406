import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Heading,
  Spinner,
  Stack,
  Select,
  FormControl,
  Button,
  Text,
  Card,
  CardBody,
  Progress,
  Center,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel, // Certifique-se de importar Text do Chakra UI se não estiver importado
} from "@chakra-ui/react";
import DropzoneBasic from "../components/dropzone";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { useAppContext } from "../context/AppContext";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import { getAllFileNames } from "../api/get-files-name-jc-api";
import RefetchAllApis from "../api/refetch-apis";
import { getToken } from "../api/get-token-api";
import { numerosNaoRequeremEvidencia } from "../helpers/mock/item-no-evid-mock";
import { useParams } from "react-router-dom";
import { descContinuation } from "../helpers/mock/desc-mock";

export default function AnexarEvidencias() {
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiDataPontos, setApiDataPontos] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");
  const { globalToken } = useAppContext();
  const [periodoSelecionado, setPeriodoSelecionado] = useState("Trimestral");
  const [apiFileNames, setApiFileNames] = useState([]);
  const [itemsComArquivos, setItemsComArquivos] = useState(0);
  const [itensPreenchidos, setItensPreenchidos] = useState(0);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [filter, setFilter] = useState("all");
  const [progressColor, setProgressColor] = useState("red");
  const [itemsDataFileName, setItemsDataFileName] = useState([]);
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const { distribuidor, setDistribuidorGlobal } = useAppContext();
  const { ano, trimestre } = useParams();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { anoGlobalAtivo, setAnoGlobalAtivo } = useAppContext();
  const { trimestreGlobalAtivo, setTrimestreGlobalAtivo } = useAppContext();

  const filterItems = (
    items,
    filter,
    itemsDataFileName,
    numerosNaoRequeremEvidencia
  ) => {
    switch (filter) {
      case "withFiles":
        return items.filter(
          (item) =>
            item.chave &&
            !numerosNaoRequeremEvidencia.includes(item.numero) && // Não incluir números que não requerem evidência
            itemsDataFileName.find((file) => file.chave === item.chave)
              ?.arquivos.length > 0
        );

      case "withoutFiles":
        return items.filter(
          (item) =>
            item.chave &&
            !numerosNaoRequeremEvidencia.includes(item.numero) && // Não incluir números que não requerem evidência
            itemsDataFileName.find((file) => file.chave === item.chave)
              ?.arquivos.length === 0
        );

      case "automaticosENumerosNaoRequeremEvidencia":
        return items.filter(
          (item) =>
            !item.evidencia || numerosNaoRequeremEvidencia.includes(item.numero)
        );

      default:
        return items;
    }
  };

  const cardColors = ["#FED7D7", "#D1FAE5"]; // Adapte as cores conforme necessário

  const getNextColor = () => {
    setCurrentColorIndex((prevIndex) => (prevIndex + 1) % cardColors.length);
  };
  const primeiraLetraMaiuscula = periodoSelecionado.charAt(0).toUpperCase();

  const duasApisJuntas = (apiData, apiDataPontos) => {
    const mergedData = [];
    apiData.forEach((item1) => {
      // Verificar se apiDataPontos é uma array antes de chamar find
      if (Array.isArray(apiDataPontos)) {
        const item2 = apiDataPontos.find(
          (item2) => item2.numero === item1.numero
        );

        if (item2) {
          const chave = item2.chave || ""; // Certifique-se de ter uma chave válida

          const mergedItem = {
            ...item1,
            ...item2,
            chave,
          };

          mergedData.push(mergedItem);
        }
      } else {
        // Lógica para lidar com o caso em que apiDataPontos não é uma array
        console.error("apiDataPontos não é uma array");
        // Pode definir um estado ou variável para indicar que ocorreu um erro
        // ou exibir uma mensagem na tela informando ao usuário sobre o problema.
      }
    });

    return mergedData;
  };

  const arrayUnido = duasApisJuntas(apiData, apiDataPontos);
  const chapters = [...new Set(arrayUnido.map((item) => item.avaliador))];
  const filteredItems = selectedChapter
    ? arrayUnido.filter((item) => item.avaliador === selectedChapter)
    : arrayUnido;

  const sortedItems = filteredItems.sort((a, b) => {
    const convertStringToNumbers = (str) => str.split(".").map(Number);

    const numbersA = convertStringToNumbers(a.numero);
    const numbersB = convertStringToNumbers(b.numero);

    for (let i = 0; i < Math.min(numbersA.length, numbersB.length); i++) {
      if (numbersA[i] !== numbersB[i]) {
        return numbersA[i] - numbersB[i];
      }
    }

    return numbersA.length - numbersB.length;
  });

  const handlePeriodoChange = (novoPeriodo) => {
    setPeriodoSelecionado(novoPeriodo);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingTable(true);
        const frase = periodoSelecionado;
        const primeirasLetras = frase
          .split(" ")
          .map((palavra) => palavra.charAt(0).toUpperCase());

        let paramForGetItemsPex = 2; // Valor padrão, pode ser ajustado conforme a necessidade

        // Verifica a condição para ajustar o parâmetro
        if (
          anoGlobal === "2023" &&
          (trimestreGlobal === "3" || trimestreGlobal === "4")
        ) {
          paramForGetItemsPex = 1; // Altera o valor para 2 se a condição for verdadeira
        }

        const tokenData = await getToken(emailGlobal, passwordGlobal);

        if (periodoSelecionado === "Trimestral") {
          const [itemsData, itemsDataPontos, itemsDataFilenames] =
            await Promise.all([
              getAllItemsPex(tokenData.access_token, paramForGetItemsPex),
              getAllItemsPexWithPoints(
                tokenData.access_token,
                periodoSelecionado,
                distribuidor,
                anoGlobal,
                trimestreGlobal,
                paramForGetItemsPex
              ),
              getAllFileNames(
                primeirasLetras,
                distribuidor,
                anoGlobal,
                trimestreGlobal,
                paramForGetItemsPex
              ),
            ]);
          setApiData(itemsData);
          setApiDataPontos(itemsDataPontos);
          setItemsDataFileName(itemsDataFilenames);
        } else {
          const [itemsData, itemsDataPontos, itemsDataFilenames] =
            await Promise.all([
              getAllItemsPex(tokenData.access_token, paramForGetItemsPex),
              getAllItemsPexWithPoints(
                tokenData.access_token,
                periodoSelecionado,
                distribuidor,
                anoGlobal,
                1,
                paramForGetItemsPex
              ),
              getAllFileNames(
                primeirasLetras,
                distribuidor,
                anoGlobal,
                1,
                paramForGetItemsPex
              ),
            ]);
          setApiData(itemsData);
          setApiDataPontos(itemsDataPontos);
          setItemsDataFileName(itemsDataFilenames);
        }

        // setApiData(itemsData);
        // setApiDataPontos(itemsDataPontos);
        // setItemsDataFileName(itemsDataFilenames);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };
    fetchData();
  }, [periodoSelecionado]);

  useEffect(() => {
    const itensPreenchidosAtualizados = sortedItems.filter((item) => {
      if (item.evidencia === "S") {
        return (
          item.chave &&
          itemsDataFileName.find((file) => file.chave === item.chave)?.arquivos
            .length > 0
        );
      } else if (
        item.evidencia === "N" &&
        !numerosNaoRequeremEvidencia.includes(item.numero)
      ) {
        return (
          item.chave &&
          itemsDataFileName.find((file) => file.chave === item.chave)?.arquivos
            .length > 0
        );
      }
      return false; // Retorna falso para os itens que não requerem evidência
    }).length;

    setItensPreenchidos(itensPreenchidosAtualizados);
  }, [sortedItems, itemsDataFileName]);

  const totalItens = arrayUnido.length;
  const porcentagemPreenchida = (itensPreenchidos / totalItens) * 100;

  const itemsFiltered = filterItems(
    sortedItems,
    filter,
    itemsDataFileName,
    numerosNaoRequeremEvidencia
  );
  const calculateProgressColor = () => {
    const percentFilled =
      sortedItems.length > 0
        ? (itensPreenchidos / sortedItems.length) * 100
        : 0;

    if (percentFilled <= 30) {
      return "red";
    } else if (percentFilled <= 70) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const handleBeforeUnload = () => {
    // Limpar o localStorage ao sair da página
    localStorage.removeItem("userProfile");
  };
  // Adicionar o manipulador de eventos beforeunload
  window.addEventListener("beforeunload", handleBeforeUnload);

  const totalItens2 = sortedItems.filter(
    (item) =>
      item.evidencia === "S" &&
      !numerosNaoRequeremEvidencia.includes(item.numero)
  ).length;

  const findConcept = (numero) => {
    const item = descContinuation.find((desc) => desc.Número === numero);
    return item ? item.Conceito : null;
  };
  return (
    <>
      {isLoadingTable ? (
        <Center h="100vh">
          <VStack>
            <Spinner size="xl" color="black" thickness="4px" />
            <Text mt="4">Aguarde... </Text>
          </VStack>
        </Center>
      ) : (
        <>
          <Heading>
            Anexar Evidências - {distribuidor} - {anoGlobal}/
            {parseInt(anoGlobal) + 1} - {trimestreGlobal}
          </Heading>
          <Stack direction="row" marginTop={"10px"}>
            <Button
              colorScheme={periodoSelecionado === "Anual" ? "yellow" : "gray"}
              onClick={() => handlePeriodoChange("Anual")}
            >
              Anual
            </Button>
            <Button
              colorScheme={
                periodoSelecionado === "Trimestral" ? "yellow" : "gray"
              }
              onClick={() => handlePeriodoChange("Trimestral")}
            >
              Trimestral
            </Button>
          </Stack>

          <Text mt="2" fontSize="lg" color="black" fontWeight="bold">
            {`Itens preenchidos: ${itensPreenchidos}/${totalItens2} (${(totalItens2 >
            0
              ? (itensPreenchidos / totalItens2) * 100
              : 0
            ).toFixed(2)}%)`}
          </Text>

          <Progress
            mt="4"
            value={(totalItens2 > 0
              ? (itensPreenchidos / totalItens2) * 100
              : 0
            ).toFixed(2)}
            colorScheme={calculateProgressColor()}
            hasStripe
          />
          <Divider borderWidth="2px" marginTop={"10px"} />

          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("withFiles")}
            colorScheme={filter === "withFiles" ? "green" : "gray"}
          >
            Ver Itens Enviados
          </Button>
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("withoutFiles")}
            colorScheme={filter === "withoutFiles" ? "green" : "gray"}
          >
            Ver Itens não Enviados
          </Button>
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("automaticosENumerosNaoRequeremEvidencia")}
            colorScheme={
              filter === "automaticosENumerosNaoRequeremEvidencia"
                ? "green"
                : "gray"
            }
          >
            Ver Itens Automáticos/Não necessita de evidência
          </Button>
          <Button
            mt="10px"
            mr="5px"
            onClick={() => setFilter("all")}
            colorScheme={filter === "all" ? "green" : "gray"}
          >
            Ver Todos
          </Button>

          <Box>
            <FormControl mt="4">
              <Select
                placeholder="Selecione o capítulo PEX"
                value={selectedChapter}
                onChange={(e) => setSelectedChapter(e.target.value)}
                borderColor="#EEF1F6"
                borderWidth="2px"
                borderRadius="md"
                focusBorderColor="greenyellow.500"
                fontWeight="bold"
                mb="10px"
              >
                {chapters.map((chapter) => (
                  <option key={chapter} value={chapter}>
                    {chapter}
                  </option>
                ))}
              </Select>
            </FormControl>
            {sortedItems.length === 0 ? (
              <RefetchAllApis buttonText="Recarregar" />
            ) : (
              itemsFiltered.map((item, index) => {
                const concept = findConcept(item.numero);
                return (
                  <Card
                    key={item.id}
                    marginTop={index > 0 ? "4" : "0"}
                    boxShadow="lg"
                    position="relative"
                    _before={{
                      content: '""',
                      display: "block",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: "6px",
                      bg:
                        itemsDataFileName.find(
                          (file) => file.chave === item.chave
                        )?.arquivos.length > 0
                          ? "green.500"
                          : numerosNaoRequeremEvidencia.includes(item.numero)
                          ? "gray.500"
                          : "none",
                      borderRadius: "4px 4px",
                    }}
                  >
                    <CardBody>
                      <Heading as="h3" size="md" mt="10px">
                        {`${item.numero} - ${item.titulo}`}
                      </Heading>
                      <Text mt={2}>{concept ? concept : item.descricao}</Text>

                      {item.evidencia === "S" &&
                        numerosNaoRequeremEvidencia.includes(item.numero) && (
                          <Box
                            mt="20px"
                            p="4"
                            bg="#718096"
                            borderRadius="md"
                            textAlign="center"
                            w="420px"
                          >
                            <Text color="white" fontSize="lg" fontWeight="bold">
                              Item não requer evidência
                            </Text>
                          </Box>
                        )}

                      {item.evidencia === "S" &&
                      !numerosNaoRequeremEvidencia.includes(item.numero) &&
                      anoGlobal === anoGlobalAtivo &&
                      trimestreGlobal === trimestreGlobalAtivo ? (
                        <DropzoneBasic chave={item.chave} />
                      ) : (
                        <>
                          {item.evidencia === "S" &&
                            !numerosNaoRequeremEvidencia.includes(
                              item.numero
                            ) &&
                            (anoGlobal !== anoGlobalAtivo ||
                              trimestreGlobal !== trimestreGlobalAtivo) && (
                              <Box
                                mt="20px"
                                p="4"
                                bg="#718096"
                                borderRadius="md"
                                textAlign="center"
                                w="420px"
                              >
                                <Text
                                  color="white"
                                  fontSize="lg"
                                  fontWeight="bold"
                                >
                                  Ano selecionado não é o ano safra ativo
                                </Text>
                              </Box>
                            )}
                        </>
                      )}

                      {item.evidencia !== "S" && (
                        <Box
                          mt="20px"
                          p="4"
                          bg="#718096"
                          borderRadius="md"
                          textAlign="center"
                          w="420px"
                        >
                          <Text color="white" fontSize="lg" fontWeight="bold">
                            Item de apuração automática
                          </Text>
                        </Box>
                      )}

                      {item.chave &&
                        itemsDataFileName.find(
                          (file) => file.chave === item.chave
                        )?.arquivos.length > 0 && (
                          <Accordion allowToggle mt="4">
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box flex="1" textAlign="left">
                                    <Text color="green" fontWeight="bold">
                                      Arquivos Enviados (
                                      {
                                        itemsDataFileName.find(
                                          (file) => file.chave === item.chave
                                        )?.arquivos.length
                                      }
                                      )
                                    </Text>
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel>
                                <ul style={{ listStyleType: "none" }}>
                                  {itemsDataFileName
                                    .find((file) => file.chave === item.chave)
                                    ?.arquivos.map((arquivo, index) => (
                                      <li key={index}>{arquivo}</li>
                                    ))}
                                </ul>
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        )}

                      <Divider borderWidth="2px" marginTop={"10px"} />
                    </CardBody>
                  </Card>
                );
              })
            )}
            <div style={{ marginLeft: "auto", marginTop: "15px" }}>
              {/* <RefetchAllApis buttonText="Atualizar itens anexados" /> */}
            </div>
          </Box>
        </>
      )}
    </>
  );
}
